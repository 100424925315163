<template lang="html">
  <div class="Dashboard">
    <Breadcrumbs :prevTitle="player.name" />
    <dashboard-top>
      <screen-title :title="player.name" />
    </dashboard-top>

    <div class="container-full">
      <div class="section">
        <div class="section__col">
          <div class="dashboard__section">
            <section-title title="Player Profile Details" />
            <UpdatePlayerProfile :player="player"/>
          </div>
        </div>
        <div class="section__col">
          <div class="dashboard__section">
            <section-title title="Player Preferences" />
            <UpdatePlayerPreference :player="player"></UpdatePlayerPreference>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";

import UpdatePlayerProfile from "@/components/Forms/UpdatePlayerProfile";
import UpdatePlayerPreference from "@/components/Forms/UpdatePlayerPreference";

export default {
  name: "UpdatePlayer",
  components: {
    UpdatePlayerProfile,
    UpdatePlayerPreference
  },
  mounted() {
    if (this.$store.state.players.current !== this.$route.params.id) {
      this.fetchUser(this.$route.params.id);
    }
  },
  methods: {
    fetchUser(id) {
      this.$store.dispatch("players/GET_PLAYER", id);
    }
  },
  computed: {
    ...mapState({
      isAvailable: state => (state.players.current == false ? false : true),
      player: state => (state.players.current ? state.players.current : null)
    })
  }
};
</script>
