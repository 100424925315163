<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }" slim v-if="player">
    <form @submit.prevent="handleSubmit(handleProfileUpdate)" class="Form">
      <formly-form
        :form="form"
        :model="profile"
        :fields="fields"
        tag="div"
        class="Form__group"
      ></formly-form>
      <button class="btn btn--outlined">Update profile account</button>
    </form>
  </ValidationObserver>
</template>

<script>
  export default {
    name: 'UpdateProfile',
    props: {
      player: {
        type: Object,
        required: true,
      },
    },
    data: function() {
      return {
        profile: {
          photo: '',
          name: '',
          email: '',
          phone: '',
          birthday: '',
          competition_level: '',
          special_considerations: '',
          emergency_contact_name: '',
          emergency_contact_number: '',
        },
        form: {},
        fields: [
          {
            key: 'photo',
            type: 'profilePicture',
            rules: 'image|size:3072',
            templateOptions: {
              type: 'file',
              label: 'Update profile picture',
            },
          },
          {
            key: 'name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Full name',
              layout: 'full',
            },
          },
          {
            key: 'birthday',
            type: 'datepicker',
            rules: 'required',
            templateOptions: {
              type: 'date',
              format: 'DD/MM/YYYY',
              label: 'Date of birth',
              layout: 'full',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'email',
            templateOptions: {
              type: 'email',
              label: 'Email address',
              layout: 'full',
            },
          },
          {
            key: 'phone',
            type: 'input',
            rules: 'phoneNumber',
            templateOptions: {
              type: 'phone',
              label: 'Phone number',
              layout: 'full',
            },
          },
          {
            key: 'competition_level',
            type: 'textarea',
            rules: 'required',
            templateOptions: {
              label: 'Highest level of play & year played',
              layout: 'full',
            },
          },
          {
            key: 'special_considerations',
            type: 'textarea',
            rules: '',
            templateOptions: {
              label: 'Special considerations',
              layout: 'full',
            },
          },
          {
            key: 'emergency_contact_name',
            type: 'input',
            rules: '',
            templateOptions: {
              label: 'Emergency Contact Name',
              layout: 'full',
            },
          },
          {
            key: 'emergency_contact_number',
            type: 'input',
            rules: 'phoneNumber',
            templateOptions: {
              label: 'Emergency Contact Phone Number',
              layout: 'full',
            },
          },
        ],
      }
    },
    mounted() {
      this.setInitialData()
    },
    methods: {
      handleProfileUpdate() {
        this.$store.dispatch('players/UPDATE_PLAYER', {
          id: this.player.id,
          data: this.profile,
        })
      },
      setInitialData() {
        this.profile.photo = this.player.photo_url
        this.profile.name = this.player.name
        this.profile.email = this.player.email
        this.profile.phone = this.player.phone || ''
        this.profile.birthday = this.player.birthday
        this.profile.competition_level = this.player.competition_level
        this.profile.special_considerations =
          this.player.special_considerations || '-'
        this.profile.emergency_contact_name =
          this.player.emergency_contact_name || '-'
        this.profile.emergency_contact_number =
          this.player.emergency_contact_number || '-'
      },
    },
  }
</script>
